import { HotelLinkInfo } from "../models/AppConfig";
import { AvailableDestinationsList, Cities, Hotel } from "../models/HotelInfo";
import { SearchList } from "./widget/types";
import i18n from "i18next"; // Importamos i18n en lugar de useTranslation()


export function hexToRgb(hex: any) {
  // Elimina el signo '#' si está presente
  hex = hex.replace(/^#/, "");

  // Divide el valor hexadecimal en componentes RGB
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

interface PlaceProps {
  place: string;
  hotelsNumber: number;
}

export function Place({ hotelsNumber, place }: PlaceProps) {
  return (
    <p className="destinationPlace">
      <span>{place}</span>
      <span>{hotelsNumber} hoteles</span>
    </p>
  );
}

export function searchCoincidencesForWidget(
  clientHotels: HotelLinkInfo[],
  value: string
): Array<SearchList> {
  let results: Array<SearchList> = [];

  value = value.trim();

  var clientHotelsFiltered = clientHotels.filter(((hotel : HotelLinkInfo)=> {
    return  (
              //Que el nombre del hotel, la ciudad o el país contengan el valor de búsqueda
              hotel.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) || 
              hotel.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) || 
              hotel.countryName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
            ) && 
            // Que esté mapeado
            hotel.cityCode !== undefined && 
            hotel.code !== undefined && 
            hotel.countryCode !== undefined);
  }))

  //Agrupamos los hoteles por ciudad indicando el número de hoteles para cada ciudad.
  const cityHotelCounter: { [city: string]: number } = clientHotelsFiltered.reduce(
    (acc, hotel) => {
      if (acc[hotel.city]) {
        acc[hotel.city]++;
      } else {
        acc[hotel.city] = 1;
      }
      return acc;
    },
    {} as { [city: string]: number }
  );


  // Convertimos el objeto en un array de lugares
  let places = Object.keys(cityHotelCounter).map((city) => {
    var cityCode = clientHotelsFiltered.find((hotel : HotelLinkInfo)=> hotel.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase() === city.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())?.cityCode;
    return {
      component: <Place hotelsNumber={cityHotelCounter[city]} place={city} />,
      label: city,
      //get city code
      id: cityCode!
    };
  });

  if (places?.length > 0) {
    results.push({
      header: i18n.t("components.widget-component.destinations"),
      category: "place",
      items: places,
    });
  }

  let hotels = getHotelCoincidencesIngoringAcents(clientHotels, value).map((hotel) => {
    return hotel as any;
  });


  if (hotels?.length > 0) {
    results.push({
      header: i18n.t("components.widget-component.hotels"),
      category: "hotel",
      items: hotels,
    });
  }

  return results;
}

export function getHotelCoincidencesIngoringAcents(
  clientHotelList: any[],
  value: string
): Array<{
  id: number;
  label: string;
  component: any;
}> {
  let results: Array<{
    id: number;
    label: string;
    component: any;
  }> = [];

  for (let i = 0; i < clientHotelList.length; i++) {
    if (
      clientHotelList[i].name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(
          value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        )
    ) {
      results.push({
        id: clientHotelList[i].hotelCode,
        label: clientHotelList[i].name,
        component: (
          <span>
            {clientHotelList[i].name}{" "}
            <small style={{ display: "block", marginLeft: "5px" }}>
              {clientHotelList[i].city}, {clientHotelList[i].countryName}.
            </small>
          </span>
        ),
      });
    }
  }

  return results;
}

export function getAvailableHotels(clientHotelList : HotelLinkInfo[]) : AvailableDestinationsList[]{

  var finalList : AvailableDestinationsList[] = [];

  var countries = clientHotelList.map((hotel: HotelLinkInfo)=>{
    return {countryName: hotel.countryName, countryCode: hotel.countryCode};
  }).filter((value, index, self) => self.indexOf(value) === index);

  let unicos = countries.filter(
    (country, index, self) =>
      index === self.findIndex(c => c.countryCode === country.countryCode)
  );
 

  unicos.forEach((country)=>{
    var countryData : AvailableDestinationsList = {
      countryName: country.countryName,
      countryCode: country.countryCode,
      cities: []
    };

    var cities = clientHotelList.filter((hotel: HotelLinkInfo)=>{
      return hotel.countryCode === country.countryCode;
    }).map((hotel: HotelLinkInfo)=>{
      var cityData = {cityName: hotel.city, cityCode: hotel.cityCode, hotels: []} as Cities;

      var hotels = clientHotelList.filter((hotel: HotelLinkInfo)=>{
        return hotel.cityCode === cityData.cityCode;
      }).map((hotel: HotelLinkInfo)=>{
        return {hotelName: hotel.name, hotelCode: hotel.code} as Hotel;
      });

      cityData.hotels = hotels;

      return cityData;
    });

    countryData.cities = cities;

    finalList.push(countryData);

  });


  return finalList;

}
