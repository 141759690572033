

import { useTranslation } from 'react-i18next';
import './TermnsAndConditionsPage.scss';
import HTMLReactParser from 'html-react-parser';

const TermnsAndConditionsPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="terms-and-conditions-page">
            <p className='bold'>{t('pages.terms-and-conditions.title')}</p>
            <br></br>

            {/* Sección 1 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.1')}</p>
            <br></br>

            {/* Sección 2 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.3')}</p>
            <br></br>


            {/* Sección 3.1 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.3')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.3.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.4')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.5')}</p>
            <br></br>


            {/* Sección 3.2 */}

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.3.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.6')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.7')}</p>
            <br></br>


            {/* Sección 3.3 */}

            <p>{t('pages.terms-and-conditions.subtitle-paragraph.3.3')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.8')}</p>
            <br></br>

            {/* Sección 3.4 */}
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.3.4')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.9')}</p>
            <br></br>


            {/* Sección 4.1 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.4')}</p>
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.4.1')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.10')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.11')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.12')}</p>
            <br></br>

            {/* Sección 4.2 */}
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.4.2')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.13')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.14')}</p>
            <br></br>

            {/* Sección 4.3 */}
            <p>{t('pages.terms-and-conditions.subtitle-paragraph.4.3')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.15')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.16')}</p>
            <br></br>


            {/* Sección 5 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.5')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.17')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.18')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.19')}</p>
            <br></br>


            {/* Sección 6 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.6')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.20')}</p>
            <br></br>


            {/* Sección 7 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.7')}</p>
            <p>{t('pages.terms-and-conditions.paragraph.21')}</p>
            <br></br>



            {/* Sección 8 */}
            <p>{t('pages.terms-and-conditions.title-paragraph.8')}</p>
            <p>{HTMLReactParser(t('pages.terms-and-conditions.paragraph.22'))}</p>
            <br></br>

        </div>
    );
}

export default TermnsAndConditionsPage;