

import { Button, Col, Row } from 'antd';
import './Template1.scss';
import LoginFormComponent from '../../../../../components/LoginForm/LoginForm';
import { Link } from 'react-router-dom';
import { GetDefaultLanguage, GetLanguageInUrl, ImagePath, IsLaguagePresentInUrl, IsSectionInPath } from '../../../../../utils/urls';
import { useEffect, useState } from 'react';
import { GetUserIcon } from '../../../../../utils/icons';
import { CarOutlined, EuroCircleOutlined, OrderedListOutlined, ShoppingCartOutlined, TagOutlined } from '@ant-design/icons';
import { useAppConfig } from '../../../../../Contexts/AppConfigContext';
import ShortCodeMethod from '../../../../../models/ShortCodeMethod';

interface Template1PageProps {
    shortCodeMethods?: ShortCodeMethod[]
}
const Template1Page = (props: Template1PageProps) : JSX.Element => {
    const appConfig = useAppConfig();

    const { shortCodeMethods } = props;

    const [clientName, setClientName] = useState<string>(appConfig?.config?.clientData?.name || 'N/D');
    const [siteName, setSiteName] = useState<string>(appConfig?.config?.siteName || 'N/D');

    const [languageUrl, setlanguageUrl] = useState<string>();

    const [hotelList, setHotelList] = useState<any[]>();

    const imagePath = ImagePath();

    const showModalLogin = () : void => {
        shortCodeMethods?.find((m)=>m.name === 'openModalLogin')?.method();
    }



    

    useEffect(()=>{
        if (appConfig?.config?.hotels){
            // Usamos la función 'reduce' para agrupar los hoteles por ciudad
            const hotelsByCity = appConfig?.config?.hotels.reduce((acc: any, hotel: any) => {
                if (acc.hasOwnProperty(hotel.city)) {
                acc[hotel.city].push(hotel);
                } else {
                acc[hotel.city] = [hotel];
                }
                return acc;
            }, {});
            
            // Convertimos el objeto en un array de pares clave-valor
            const sortedHotels = Object.entries(hotelsByCity);
            
            // Ordenamos el array en función de las ciudades
            sortedHotels.sort((a, b) => a[0].localeCompare(b[0]));
            
            // Reconstruimos el objeto a partir del array ordenado
            const hotelsByCitySorted = sortedHotels.reduce((acc: any, [city, hotels]) => {
                acc[city] = hotels;
                return acc;
            }, {});

            setSiteName(appConfig?.config?.siteName || 'N/D');
            setClientName(appConfig?.config?.clientData?.name || 'N/D');

            setHotelList(hotelsByCitySorted);
        }
    },[appConfig])




    useEffect(()=>{
        var defaultLanguage = GetDefaultLanguage();

        if (IsLaguagePresentInUrl()){
            setlanguageUrl(GetLanguageInUrl());
        }else{
            setlanguageUrl(defaultLanguage);
        }
    },[])

    return(
        <div className="template-1">
            <div className="module-0">
                <div className="background" style={{backgroundImage:`url(/images/theme-1-background-0.png)`}}>
                    <div className="filter-layout"></div>
                    <div className="color-layout"></div>
                    <div className="title">
                        <p>BIENVENIDOS <small>a</small></p>
                        <p>{siteName} de {clientName}</p>
                    </div>
                    <div className="actions">
                        <Link className="go-register" to={`${languageUrl}/register`}>
                            Regístrese
                        </Link>
                        <Button className="go-login" onClick={showModalLogin}>
                            Acceso clientes
                        </Button>
                    </div>
                </div>
            </div>
            <div className="module-1">
                    <Row gutter={16} className="grid-row">
                        <Col className="grid-column text" xs={24} md={12}>
                            <p> <span className="max">Disfrute</span> de todas 
                           las ventajas como <span className="max">Colaborador</span></p>
                        </Col>
                        <Col className="grid-column" xs={24} md={12}>
                            <div className="image-container" style={{backgroundImage: `url(/images/grid1.png)`}} title="Disfrute de todas las ventajas como colaborador">
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} className="grid-row">
                        <Col className="grid-column text" xs={24} md={12}>
                            <p> <span className="max">Gestione</span> desde esta plataforma sus <span className="max">reservas y consultas</span></p>
                        </Col>
                        <Col className="grid-column" xs={24} md={12}>
                            <div className="image-container" style={{backgroundImage: `url(/images/grid2.png)`}} title="Gestione desde esta plataforma susreservas y consultas">
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={16} className="grid-row">
                        <Col className="grid-column" xs={24} md={12}>
                            <div className="image-container" style={{backgroundImage: `url(/images/grid3.png)`}} title="Descubra las ventajas de {siteName}">
                            </div>
                        </Col>
                        <Col className="grid-column text" xs={24} md={12}>
                            <p><span className="max">Descubra</span> las ventajas de <span className="max">{siteName}</span></p>    
                        </Col>
                    </Row>
                    <Row gutter={16} className="grid-row">
                        <Col className="grid-column" xs={24} md={12}>
                            <div className="image-container" style={{backgroundImage: `url(/images/grid4.png)`}} title="Únase a nuestro sistema de fidelización para agentes de viaje">
                            </div>
                        </Col>
                        <Col className="grid-column text" xs={24} md={12}>
                            <p><span className="max">Únase a</span> nuestro sistema de fidelización para <span className="max">agentes de viaje</span></p>
                        </Col>
                    </Row>
            </div>
            <div className="module-2">
                <img src={`/images/senator-summer-2023.png`} title="Hotel Brand HR Summer 2023"/>
            </div>
            <div className="module-3">
                <h3 className="main-title">Sistema de retribución B2B</h3>
                <h3 className="main-title">para agentes de viaje</h3>
                <Row gutter={16} className="grid-row">
                    <Col className="grid-column" xs={24} md={12}>
                        <div className="image-container" style={{backgroundImage: `url(/images/grid5.png)`}}>
                        </div>
                    </Col>
                    <Col className="grid-column" xs={24} md={12}>
                        <h3 className='title'>{siteName}</h3>
                        <p>Descubre todo lo que te ofrecemos por pertenecer a nuestra plataforma de fidelización para agentes de viajes.</p>
                        <p>Gana dinero con las reservas de tus clientes y canjéalo por estancias o magníficas ventajas como cheques regalo.</p>
                    </Col>
                </Row>
                <Row gutter={16} className="grid-row">
                    <Col className="grid-column" xs={24} md={12}>
                        <h3 className='title'>Conoce {siteName}</h3>
                        <p>Desde nuestra plataforma {siteName} podrás administrar tus recompensas y comprobar en todo momento el estado de tus reservas.</p>
                        <p>Además, podrás consultar tu posición en el ranking de agentes de viajes y conocer las últimas novedades de Hotel Brand S.A.</p>
                        
                    </Col>
                    <Col className="grid-column" xs={24} md={12}>
                        <div className="image-container" style={{backgroundImage: `url(/images/grid6.png)`}}>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="module-4">
                <div className="background" style={{backgroundImage:`url(/images/senator-background1.png)`}}>
                    <div className="filter-layout"></div>
                    <div className="title">
                        <p>DESCUBRE y EXPLORA</p>
                        <p>Nuevos lugares</p>
                    </div>
                </div>
            </div>
            <div className="module-5">
                <p className="title">
                    Acceda a Hotel Brand PRO Rewards
                </p>
                <Row gutter={{xs:0, lg:50}}>
                    <Col xs={24} lg={12}>
                        <ul className="template1-list">
                            <li className="list-title">
                                <div><ShoppingCartOutlined className="list-icon"/></div>
                                <div><span className="text">Sencillo proceso de reserva</span></div>
                            </li>
                            <li className="list-description">
                                Realice las reservas de sus clientes de una forma rápida y muy cómoda a través de nuestra plataforma.
                            </li>

                            <li className="list-title">
                                <div className="">
                                    <OrderedListOutlined className="list-icon"/>
                                </div>
                                <div className="text">
                                    Registro de todas las operaciones
                                </div>
                            </li>
                            <li className="list-description">
                                
                                Disponga del registro de todas las operaciones que haya realizado en un mismo sitio para que pueda realizar un seguimiento facilmente.
                            </li>

                            <li className="list-title">
                                <div className="">
                                    <EuroCircleOutlined className="list-icon"/>
                                </div>
                                <div className="text">
                                    Sistema de recompensas Hotel Brand Rewards
                                </div>
                            </li>
                            <li className="list-description">
                                Cada vez que usted realiza una reserva en Hotel Brand PRO Rewards obtiene puntos en su cuenta de Hotel Brand Rewards que podrá canjear por noches
                                de hotel gratuitas y muchas otras ventajas.
                            </li>

                            <li className="list-title">
                                <div className="">
                                    <TagOutlined className="list-icon"/>
                                </div>
                                <div className="text">
                                    Acceso a ofertas e información exclusivas
                                </div>
                            </li>
                            <li className="list-description">
                                Mantégase informado de todas nuestras ofertas y promociones exclusivas para que pueda ofrecer a sus clientes las mejores estancias y experiencias
                                al mejor precio.
                            </li>
                        </ul>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="login-container">
                            <h3>Iniciar sesión</h3>
                            <LoginFormComponent 
                                onLogin={shortCodeMethods?.find((m)=>m.name === 'onDoLogin')?.method as (userName: string, password: string)=>void}
                                haveError={false}
                                doingLogin={false}
                                onForgetPassword={shortCodeMethods?.find((m)=>m.name === 'onForgetPassword')?.method as ()=>void}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="module-6">
                <Row gutter={0} className="services">
                    <Col xs={24} md={6} className="service-box title">
                        Servicios
                    </Col>
                    <Col xs={24} md={6} className="service-box image">
                        <img src={`/images/service1.png`} />
                    </Col>
                    <Col xs={24} md={6} className="service-box image">
                        <img src={`/images/service2.png`} />
                    </Col>
                    <Col xs={24} md={6} className="service-box image">
                        <img src={`/images/service3.png`} />
                    </Col>
                </Row>

                <Row gutter={0} className="destinations">
                    <Col xs={24} md={6} className="service-box image">
                        <img src={`/images/destination1.png`} />
                    </Col>
                    <Col xs={24} md={6} className="service-box image">
                        <img src={`/images/destination2.png`} />
                    </Col>
                    <Col xs={24} md={6} className="service-box image">
                        <img src={`/images/destination3.png`} />
                    </Col>
                    <Col xs={24} md={6} className="service-box title">
                        Destinos
                    </Col>
                </Row>

            </div>
            <div className="module-7">
                <Row gutter={16}>
                    {
                        hotelList ? 
                            Object.entries(hotelList!).map(([city, hotels]) => (
                                <Col xs={8} lg={6} key={city} className="city">
                                    <h2 className="city-name">{city}</h2>
                                    <ul className="hotel-list">
                                        {hotels?.map((hotel: any) => (
                                            <li key={hotel.name} className="hotel-name">
                                                {
                                                    hotel.link ? <a className="app-link no-hover no-underline" href={hotel.link} target='_blank'>{hotel.name}</a> : <>{hotel.name}</>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            ))
                            :
                            <></>
                    }
                </Row>
            
            </div>
        </div>
    )
}

export default Template1Page;