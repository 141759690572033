import { useTranslation } from "react-i18next";
import { useBookingEngineContext } from "../../../Contexts/EngineDataContext";
import { useEffect, useRef, useState } from "react";
import { BookingData, Cart, EngineWidget, NoData, Pagination, PostponedBookings } from "../../../components";
import { Step as EngineSteps }  from "@beds2b-group/reusable-components";
import { fetchHotelAvailability, fetchLocationAvailability, fetchPostponedBookings, fetchUserHistory, GetPaymentsMethods } from "../../../api/repositoryEP";

import './BookingEnginePage.scss';
import LoadingPage from "../../Commons/LoadingPage/LoadingPage";
import { HistoryItem } from "@beds2b-group/reusable-components/dist/types";

import '../../../components/booking-engine/HotelModalWrapper'
import '../../../components/booking-engine/RoomModalWrapper'
import '../../../components/booking-engine/RoomRateModalWrapper'

import { useModal } from "@gluedigital/modal";
import { AvailabilityRequest } from "../../../models/availability/AvailabilityRequest";

import { EngineDestinationTypeHotel, EngineDestinationTypeLocation, emptyLocationAvailabilityResponse, emptyHotelAvailabilityResponse } from "../../../utils/constants";
import { LocationAvailabilityResponse } from "../../../models/availability/LocationsAvailability";
import { HotelAvailabilityResponse } from "../../../models/availability/HotelAvailability";
import { LocationToAppAvailability } from "../../../utils/LocationToAppAvailability";
import { HotelsToAppAvailability } from "../../../utils/HotelsToAppAvailability";
import { Alert, Button, Col, Modal, Row } from "antd";
import hotelUtils from "../../../mocks/hotels";
import { useCart } from "../../../Contexts/CartContexts";
import { useNavigate } from "react-router-dom";
import { GetFormattedUrl, GetLanguageInUrl, IsLaguagePresentInUrl } from "../../../utils/urls";
import PostponedBooking from "../../../models/PostponedBooking";
import { useAppConfig } from "../../../Contexts/AppConfigContext";
import { getAvailableHotels, searchCoincidencesForWidget } from "../../../utils/utils";
import { useSessionData } from "../../../Contexts/SessionDataContext";
import moment from "moment";
import { useCurrencyData } from "../../../Contexts/CurrencyContext";
import { PaymentMethodData } from "../../../models/PaymentsMethodsData";

const BookingEnginePage = () : JSX.Element => {

    const navigate = useNavigate();

    const cartContext = useCart();
    const appConfig  = useAppConfig();
    const sessionData = useSessionData();

    const modal = useModal();


    const bookingEngineContext = useBookingEngineContext();

    const [actualStepRoomSelector, setActualStepRoomSelector] = useState<number>(0);
    const [actualStepBooking, setActualStepBooking] = useState<number>(1);
    const [paymentsMethods,setPaymentsMethods] = useState<PaymentMethodData[]>([]);
    
    const { t } = useTranslation();


    const bookingDataFormRef = useRef<any>(null); // Crear la referencia para BookingDataForm

    // Función para hacer scroll al botón de pago
    const scrollToPayButton = () => {
        if (bookingDataFormRef.current?.scrollToButton) {
            bookingDataFormRef.current.scrollToButton(); // Llamamos a la función scrollToButton de BookingDataForm
        } else {
            console.error("🔴 No se pudo acceder a la función scrollToButton");
        }
    };

    const onChangeStep = (step: number) => {
        //Permitimos el cambio del 3 al 2 y del 2 al 3 siempre que:
        //  - El paso 2 esté completo: Se hayan seleccionado todas las habitaciones
        //  - Del paso 3 al 2 se puede pasar siempre.
        // No se permiten otros cambios de paso.
        var newStep = step+1;

        if (actualStepBooking === 3 && newStep === 2){
            setActualStepBooking(newStep);
            setActualStepRoomSelector(1);
        }else if (actualStepBooking === 2 && newStep === 3){
            if (cartContext.data.rooms.length == bookingEngineContext.rooms.length){
                setActualStepBooking(newStep);
                setActualStepRoomSelector(bookingEngineContext.rooms.length+1);
            }
        }

        
    };

    const [userHistory, setUserHistory] = useState<Array<HistoryItem>>([]);
    const getUserHistory = () => {
        fetchUserHistory().then((response) => {
            if (response){
                setUserHistory(response.data)
            }else{
                setUserHistory([])
            }
        });
    }

    const [searchCoincidences, setSearchCoincidences] = useState<Array<any>>([]);
    const getSearchCoincidences = (v: string) => {
        if (v.length < 3) return setSearchCoincidences([]);

        var coincidences = searchCoincidencesForWidget(appConfig.config.hotels, v);

        setSearchCoincidences(coincidences);
    }

    const [availabilityType, setAvailabilityType] = useState<number>(EngineDestinationTypeLocation); // [hotel, room
    const [isGettingAvailability, setIsGettingAvailability] = useState<boolean>(false); 
    const [locationAvailability, setLocationAvailability] = useState<LocationAvailabilityResponse>(emptyLocationAvailabilityResponse);
    const [hotelAvailability, setHotelAvailability] = useState<HotelAvailabilityResponse>(emptyHotelAvailabilityResponse);
    const [showNoDispo, setShowNoDispo] = useState<boolean>(false);
    const getAvailability = () => {
        setIsGettingAvailability(true);

        if (bookingEngineContext.dates.from == null || bookingEngineContext.dates.to == null) return;

        var actualLanguage = IsLaguagePresentInUrl() ? GetLanguageInUrl().substring(1) : appConfig.config.availableLanguages.find((l) => l.isDefault)?.shortCode;
        if (actualLanguage){
            var availabilityData : AvailabilityRequest = {
                LanguageIsoCode: actualLanguage,
                DestinationType: bookingEngineContext.searchType,
                DestinationCodes: [bookingEngineContext.searchCode],
                AccommodationDates: {
                    Format: appConfig.config.dateFormat,
                    From: bookingEngineContext.dates.from,
                    To: bookingEngineContext.dates.to
                },
                StaticDataDetailLevel: 1,
                PromotionalCode: bookingEngineContext.promotionalCode,
                RoomConfiguration: [],
                IncludeAvailabilityWithoutquota: false,
                IncludeAlternatives: false,
                IncludeCrossSellingProducts: false,
                MarketCode: sessionData.userData.market,
                DestinationName: sessionStorage.getItem("lastLabel") || bookingEngineContext.label
            }
    
            bookingEngineContext.rooms.forEach((room) => {
                availabilityData.RoomConfiguration.push({
                    RoomCode: "",
                    Occupancy: {
                        Adults: {
                            // La edad debe informarse al servicio Rellenamos le nodo ages con un array que contenga room.persons.adults.quantity veces la edad 24
                            Ages: Array(room.persons.adults.quantity).fill(24),
                            Number: room.persons.adults.quantity
                        }, 
                        Teenagers:{
                            Ages: [],
                            Number: 0
                        },
                        Babies: {
                            Ages: [],
                            Number: 0
                        },
                        Children: {
                            Ages: Object.values(room.persons.kids.ages),
                            Number: room.persons.kids.quantity
                        }
                    },
                    MealCode: []
                })
            });
    
            //Vamos al siguiente paso: Selección.
            setActualStepBooking(2);
            
            if (bookingEngineContext.searchType === EngineDestinationTypeLocation){
                setAvailabilityType(EngineDestinationTypeLocation);
                setActualStepRoomSelector(0);
                fetchLocationAvailability(availabilityData).then((response) => {
                    if (response){
                        setLocationAvailability(response.data)
                    }else{
                        setLocationAvailability(emptyLocationAvailabilityResponse)
                    }
                }).finally(()=>{
                    setIsGettingAvailability(false);
                })
            }else{
                //Mostramos la paginación de las habitaciones del hotel
                setAvailabilityType(EngineDestinationTypeHotel);
                setActualStepRoomSelector(1);
                fetchHotelAvailability(availabilityData).then((response) => {
                    if (response && response.data && response.data.hotelAvailabilities && response.data.hotelAvailabilities.length > 0){
                        setShowNoDispo(false);
                        var hotelData = response.data.hotels[0];
                        console.log("hotelStaticData", response.data);
                        cartContext.setHotelData({
                            address: hotelData.address?.streetName ?? '',
                            code: sessionStorage.getItem("lastSearchCode") || bookingEngineContext.searchCode,
                            name: hotelData.name,
                            email: hotelData.contactData?.email ?? '',
                            mainImage: hotelData.images && hotelData.images.length > 0 ? hotelData.images[0].url : '',
                            otherInfo: [],
                            personOfContact: hotelData.contactData?.contactPerson ?? '',
                            phone: hotelData.contactData ? `(${hotelData.contactData?.prefix}) ${hotelData.contactData?.number}` : '',
                            website: hotelData.contactData?.website ?? '',
                            longitude : hotelData.address.longitude,
                            latitude : hotelData.address.latitude
                        });

                        cartContext.updateHashCart(response.data.availabilityRequestId);
                        setHotelAvailability(response.data)
                        setTimeout(onSetDefaultLabel, 500);
                    }else{
                        setShowNoDispo(true);
                        cartContext.clearCart();
                        setTimeout(onSetDefaultLabel, 500);
                        setRoomsAvailability([]);
                        setHotelAvailability(emptyHotelAvailabilityResponse)
                    }
                }).finally(()=>{
                    setIsGettingAvailability(false);
                })
            }
        }
        
    }

    const [roomSteps, setRoomSteps] = useState<any[]>([]);
    const getRoomSteps = () : void => {
        const roomSteps : any[] = [];
        bookingEngineContext.rooms.forEach((room) => {
            roomSteps.push({
                adults: room.persons.adults.quantity,
                childs: room.persons.kids.quantity
            })
        });

        setRoomSteps(roomSteps);
    }


    const [availableDestinations, setAvailableDestinations] = useState<Array<any>>([]);
    const getAvilableDestinations = () => {
        setAvailableDestinations(getAvailableHotels(appConfig.config.hotels));
    }

    

    const onSelectRoom = (roomData: any, mealData: any, rateData: any) => {

        console.log("onSelectRoom in BookingEnginePage.tsx;", rateData);
        console.log(roomData);
        console.log(mealData);
        console.log(rateData);

        var currency = mealData.isPvp ? mealData.pvp.currency : mealData.net.currency;

        cartContext.addRoomToCart({
            roomIndex: actualStepRoomSelector,
            mainImage: roomData.images[0] ?? '',
            name: roomData.name,
            code: roomData.code,
            price: {
                currency: currency,
                pvp: mealData.pvp?.amount,
                commission: mealData.commission?.amount,
                isPvp: mealData.isPvp,
                net: mealData.net?.amount,
            },
            occupancy:{
                adults: bookingEngineContext.rooms[actualStepRoomSelector-1].persons.adults.quantity,
                childs: bookingEngineContext.rooms[actualStepRoomSelector-1].persons.kids.quantity,
                childsAges: bookingEngineContext.rooms[actualStepRoomSelector-1].persons.kids.ages
            },
            rateInfo:{
                name: rateData.name,
                code: rateData.code,
                isOffer: rateData.isOffer,
                cancelPolicies: rateData.cancelPolicies,
            },
            mealInfo:{
                code: mealData.code,
                commission: mealData.commission,
                discount: mealData.discount,
                isPvp: mealData.isPvp,
                name: mealData.name,
                net: mealData.net,
                pvp: mealData.pvp,
                ratekey: mealData.rateKey,
            }
        })

        // Go next step
        setActualStepRoomSelector(cartContext.whatIsTheNextRoomIndex());
    }

    const onRemoveRoomFromCart = (roomIndex: number, roomCode: string) => {
        setActualStepRoomSelector(roomIndex);
        setActualStepBooking(2);
    }

    const [isSimulateBookingPaymentOpened, setIsSimulateBookingPaymentOpened] = useState<boolean>(false);
    const toogleSimulateBookingPayment = () => {
        console.log("toogleSimulateBookingPayment");
        setIsSimulateBookingPaymentOpened(!isSimulateBookingPaymentOpened);
    }


    const [postponedBookings, setPostponedBookings] = useState<PostponedBooking[]>([]);
    const getPostponedBookings = () => {
        fetchPostponedBookings().then((response) => {
            if (response){
                setPostponedBookings(response.data)
                }else{
                    setPostponedBookings([])
                }
            }
        )
    }    
    
    const [isCartVisible, setIsCartVisible] = useState<boolean>(false);
    const toogleCart = () => {
        setIsCartVisible(!isCartVisible);
    }

    const currencyContext = useCurrencyData();

    

    const [roomsAvailability, setRoomsAvailability] = useState<JSX.Element[]>([]);
    useEffect(()=>{
        
        if (hotelAvailability && hotelAvailability.hotelAvailabilities && hotelAvailability.hotelAvailabilities.length >0 && actualStepRoomSelector > 0){
            var actualCurrencyIso = currencyContext.currencyData.currencySelectedISO ?? 'EUR';
            var actualConversionRate = currencyContext.currencyData.currenciesAvailable.find((c) => c.currencyISO === actualCurrencyIso)?.exchangeConversionValue ?? 1;
            let avail = HotelsToAppAvailability(hotelAvailability, actualStepRoomSelector, onSelectRoom, modal, {
                currency: actualCurrencyIso,
                exchangeRate: actualConversionRate
            });
            console.log("me llega",avail )
            setRoomsAvailability(avail)
        }

        if (actualStepRoomSelector > roomSteps.length){
            setActualStepBooking(3);
        }else if(actualStepRoomSelector > 0 && actualStepRoomSelector <= roomSteps.length){
            setActualStepBooking(2);
        }
    },[hotelAvailability, actualStepRoomSelector, currencyContext.currencyData]);

    useEffect(()=>{
        if (bookingEngineContext.isValidData()){
            
            cartContext.setDates({
                checkIn: bookingEngineContext.dates.from ?? new Date(),
                checkOut: bookingEngineContext.dates.to ?? new Date(),
            });

            cartContext.updateHashCart("");
            getAvailability();
            getRoomSteps();
        }
    },[bookingEngineContext]);


    useEffect(()=>{
       console.log("aqui tenemos ", hotelAvailability) 
    },[hotelAvailability]);

    const [defaultLabel, setDefaultLabel] = useState<string>("");
    const onSetDefaultLabel = () => {
        var lastLabel = sessionStorage.getItem("lastLabel");
        setDefaultLabel(lastLabel ?? "");
    }

    const getPaymentsMethods = () => {
        GetPaymentsMethods().then((r) => {
            if(r?.type == 1 && r.data){
                setPaymentsMethods(r.data as PaymentMethodData[]);
            }
        });
    }



    useEffect(()=>{
        getUserHistory();
        getAvilableDestinations();
        getPostponedBookings();
        onSetDefaultLabel();
        getPaymentsMethods();
    },[]);



    

    return(
        <div className="booking-engine-page">
            <Modal closable title="Simulador de pago" open={isSimulateBookingPaymentOpened} onCancel={toogleSimulateBookingPayment}
                footer={(_, { CancelBtn }) => (
                <>
                    <Button className="app-button danger" danger onClick={()=>{
                        //Go to customer voucher
                        toogleSimulateBookingPayment();
                        navigate(GetFormattedUrl(`private/payment-ko`));
                    }}>Simular KO</Button>
                    <Button className="app-button" onClick={()=>{
                        //Clear engine search
                        bookingEngineContext.clearContext();
                        //Clear cart
                        cartContext.clearCart();
                        //Go to customer voucher
                        navigate(GetFormattedUrl(`private/payment-ok-customer-voucher/123`));
                    }}>Simular OK</Button>
                    <CancelBtn />
                </>
                )}
            >
                <p>Por favor, pulse una de las siguientes opciones para simular el flujo del pago</p>
            </Modal>
            
            <div className="widget-container">
                <BookingUpdateContext />
                <EngineWidget 
                        userHistory={userHistory} 
                        onGetCoincidences={getSearchCoincidences} 
                        searchCoincidences={searchCoincidences} 
                        availableDestinations={availableDestinations}
                />
            </div>
            
            <div className="booking-container">
                
                {
                isGettingAvailability ? 
                    <LoadingPage />
                :
                (actualStepBooking === 3 || (locationAvailability && locationAvailability.hotels?.length > 0)  || (roomsAvailability && roomsAvailability.length > 0)) 
                    ? 
                    <>
                        <div className="booking-engine-pagination">
                            <Pagination actualStep={actualStepBooking} onChangeStep={onChangeStep}/>
                        </div>
                        <div className="booking-engine-steps">
                            {
                                actualStepRoomSelector > 0 && roomSteps.length > 1 ? 
                                    <EngineSteps actualStep={actualStepRoomSelector} occupancy={roomSteps} onStepChange={(step: number)=>{ 
                                        setActualStepRoomSelector(step);
                                    }}/>
                                :
                                    <></>
                            }
                        </div>
                        <div className={`booking-engine-result`}>
                            
                            <Row gutter={16}>
                                <Col xs={(appConfig.device === "mobile" || availabilityType === EngineDestinationTypeLocation ? 24 : 18)}>
                                    
                                    {
                                        availabilityType === EngineDestinationTypeLocation ?
                                                LocationToAppAvailability(locationAvailability, bookingEngineContext, modal, {
                                                    currency: currencyContext.currencyData.currencySelectedISO ?? 'EUR',
                                                    exchangeRate: currencyContext.currencyData.currenciesAvailable.find((c) => c.currencyISO === currencyContext.currencyData.currencySelectedISO)?.exchangeConversionValue ?? 1
                                                },
                                                appConfig.config.hotels
                                            ) // Mostraoms la disponibilidad de localización, es el paso 2
                                            :
                                                (
                                                    actualStepRoomSelector > 0 && actualStepRoomSelector <= roomSteps.length ?
                                                        <RoomsAvailabilityComponent avail={roomsAvailability} /> // Mostramos la disponibilidad de hoteles, es el paso 2
                                                    :
                                                        <BookingData
                                                         paymentsMethods={paymentsMethods} 
                                                         availData={roomsAvailability}
                                                         ref={bookingDataFormRef} // Pasamos la referencia a BookingDataForm
                                                         /> //Mostramos el formulario, es el paso 3
                                                )
                                    }
                                </Col>
                                <Col className={`cart ${appConfig.device} ${isCartVisible ? 'visible' : 'hidden'}`} xs={(availabilityType === EngineDestinationTypeLocation ? 0 : 6)}>
                                    <Cart 
                                        scrollToPayButton={scrollToPayButton}
                                        onClickInPayButton={toogleSimulateBookingPayment} 
                                        hotelData={hotelUtils.hotel} 
                                        from={bookingEngineContext.dates.from} 
                                        to={bookingEngineContext.dates.to} 
                                        notifyRemoveRoom={onRemoveRoomFromCart}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </>
                    :
                    <div className="alert-search-availability">
                        {
                            showNoDispo 
                                ? 
                                    <NoData message={t('pages.bookings-page.no-availability.description')!}/> 
                                :
                                    <Alert message={t('pages.bookings-page.search-availability.title')} description={t('pages.bookings-page.search-availability.description')} type="info" showIcon />
                        }
                    </div>
                    

            }
            
           
            </div>



            <div className="postponed-bookings-container">
                <PostponedBookings list={postponedBookings}/>
            </div>

            
        </div>
    )
}

export default BookingEnginePage;

const RoomsAvailabilityComponent = ({ avail }: { avail: any[] }) : JSX.Element => {

    const [availData, setAvailData] = useState<JSX.Element[]>([]);

    useEffect(()=>{
        setAvailData([]);
        setTimeout(()=>{
            setAvailData(avail);
        }, 0);
    },[avail]);

    return (
      <>
        {availData}
      </>
    );
  };

const BookingUpdateContext = () : JSX.Element => {
    const appConfig = useAppConfig();

    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const bookingEngineContext = useBookingEngineContext();

    const [hotelCodeFromUrl] = useState<string>(urlParams.get('hc') ?? '');
    useEffect(()=>{
        if (hotelCodeFromUrl){
            var hotelData = appConfig.config.hotels.find((h) => h.code === hotelCodeFromUrl);
            sessionStorage.setItem("lastSearchCode", hotelCodeFromUrl);
            sessionStorage.setItem("lastSearchType", "0");
            bookingEngineContext.updateContext({label: hotelData?.name ?? ''});
        }
    },[hotelCodeFromUrl]);


    const [cityCodeFromUrl] = useState<string>(urlParams.get('cc') ?? '');
    useEffect(()=>{
    if (cityCodeFromUrl){
        var hotelData = appConfig.config.hotels.find((h) => h.cityCode === cityCodeFromUrl);
        sessionStorage.setItem("lastSearchCode", cityCodeFromUrl);
        sessionStorage.setItem("lastSearchType", "1");
        bookingEngineContext.updateContext({label: hotelData?.city ?? ''});
    }
    },[cityCodeFromUrl]);


    const [dateFromFromUrl] = useState<string>(urlParams.get('df') ?? '');
    useEffect(()=>{
      if (dateFromFromUrl !== '' && dateFromFromUrl !== 'Invalid date'){
        sessionStorage.setItem("lastFrom", moment(dateFromFromUrl).toDate().toISOString());
      }
    },[dateFromFromUrl]);

    const [dateToFromUrl] = useState<string>(urlParams.get('dt') ?? '');
    useEffect(()=>{
      if (dateToFromUrl !== '' && dateFromFromUrl !== 'Invalid date'){
        sessionStorage.setItem("lastTo", moment(dateToFromUrl).toDate().toISOString());
      }
    },[dateToFromUrl]);

    return(
        <div></div>
    )
}