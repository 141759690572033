import { useTranslation } from 'react-i18next';
import './CookiesPage.scss'
import HTMLReactParser from 'html-react-parser';

const CookiesPage = () : JSX.Element => {

    const { t } = useTranslation();
    
    return(
        <div className="cookies-page">
                <p className='bold'>{t('pages.cookies.title')}</p>
                           <br></br>
               
                           {/* Sección 1 */}
                           <p>{t('pages.cookies.title-paragraph.1')}</p>
                           <p>{t('pages.cookies.paragraph.1')}</p>
                           <br></br>
               
                           {/* Sección 2 */}
                           <p>{t('pages.cookies.title-paragraph.2')}</p>
                           <p>{t('pages.cookies.paragraph.2')}</p>
                           <br></br>
               
               
                           {/* Sección 3 */}
                           <p>{t('pages.cookies.title-paragraph.3')}</p>
                           <p>{t('pages.cookies.paragraph.3')}</p>
                           <p>{t('pages.cookies.paragraph.4')}</p>
                           <p>{t('pages.cookies.paragraph.5')}</p>
                           <p>{t('pages.cookies.paragraph.6')}</p>
                           <p>{t('pages.cookies.paragraph.7')}</p>
                           <p>{t('pages.cookies.paragraph.8')}</p>
                           <br></br>
               
               
               
                           {/* Sección 4. */}
                           <p>{t('pages.cookies.title-paragraph.4')}</p>
                           <p>{t('pages.cookies.paragraph.9')}</p>
                           <p>{t('pages.cookies.paragraph.10')}</p>
                           <p>{t('pages.cookies.paragraph.11')}</p>
                           <p>{t('pages.cookies.paragraph.12')}</p>
                           <p>{t('pages.cookies.paragraph.13')}</p>
                           <p>{t('pages.cookies.paragraph.14')}</p>
                           <br></br>
               
               
               
                           {/* Sección 5 */}
                           <p>{t('pages.cookies.title-paragraph.5')}</p>
                           <p>{t('pages.cookies.paragraph.15')}</p>
                           <p>{t('pages.cookies.paragraph.16')}</p>
                           <p>{t('pages.cookies.paragraph.17')}</p>
                           <p>{t('pages.cookies.paragraph.18')}</p>
                           <p>{t('pages.cookies.paragraph.19')}</p>
                           <br></br>
               
               
                           {/* Sección 6 */}
                           <p>{t('pages.cookies.title-paragraph.6')}</p>
                           <p>{t('pages.cookies.paragraph.20')}</p>
                           <p>{t('pages.cookies.paragraph.21')}</p>
                           <p>{t('pages.cookies.paragraph.22')}</p>
                           <br></br>
               
               
                           {/* Sección 7 */}
                           <p>{t('pages.cookies.title-paragraph.7')}</p>
                           <p>{t('pages.cookies.paragraph.23')}</p>
                           <br></br>
               
                           {/* Sección 8 */}
                           <p>{t('pages.cookies.title-paragraph.8')}</p>
                           <p>{HTMLReactParser(t('pages.cookies.paragraph.24'))}</p>
                           <br></br>

               
                       </div>
                   );
               }

export default CookiesPage;