

import { Col, List, Row } from 'antd';
import BookingInfo from '../../../models/BookingInfo';
import { useTranslation } from 'react-i18next';


import './BookingListItemComponent.scss';


interface Props{
    item: BookingInfo,
    getTagStatus: (item: BookingInfo) => JSX.Element,
    getLocatorInfo: (item: BookingInfo) => JSX.Element,
    getActions: (item: BookingInfo) => JSX.Element,
    getDatesInfo: (item: BookingInfo) => JSX.Element,
    getprice: (item: BookingInfo) => JSX.Element,
    getUserInfo: (item: BookingInfo) => JSX.Element
}

const BookingListItemComponent = (props: Props) : JSX.Element => {
    const { t } = useTranslation();

    const {item, getTagStatus, getLocatorInfo, getActions, getDatesInfo, getprice, getUserInfo} = props;
    
    return(
        <List.Item className="booking-list-item">
            <Row gutter={0}>
                <Col xs={10} className="list-item">
                    {getTagStatus(item)}
                </Col>
                <Col xs={10} className="list-item">
                    {getLocatorInfo(item)}
                </Col>
                <Col xs={4} className="list-item flex-center">
                    {getActions(item)}
                </Col>
            </Row>
            <Row className="list-additional-info">
                <Col xs={12}  className="list-item">
                    <span>{t('components.bookings-list.hotels-date')}</span>
                    {getDatesInfo(item)}
                </Col>
                <Col xs={12}  className="list-item">
                    <span>{t('components.bookings-list.booking-header')}</span>
                    {item.ownerName}
                </Col>
                <Col xs={12}  className="list-item">
                    <span>{t('components.bookings-list.price')}:</span>
                    {getprice(item)}
                </Col>
                <Col xs={12}  className="list-item">
                    <span>{t('components.bookings-list.agent-data')}:</span>
                    {getUserInfo(item)}
                </Col>
            </Row>
            
        </List.Item>
    )
}

export default BookingListItemComponent;