
import { Col, List, Pagination, Popconfirm, Row, Table, Tag, Tooltip, notification } from 'antd';
import BookingInfo, { SmallBookingDataList } from '../../../models/BookingInfo';
import './BookingsListComponent.scss'
import { useTranslation } from 'react-i18next';
import { EditOutlined, InfoCircleOutlined, SnippetsOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { GetPaginationLocale } from '../../../utils/locales';
import { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import MoneyComponent from '../../common/MoneyComponent/MoneyComponent';
import { BookingListItem } from '../..';

interface BookingsListComponentProps {
    bookingsInfo: SmallBookingDataList,
    search: (rows: number, page: number) => void,
    onShowBookingDetail: (bookingId: string) => void,
    onShowCustomerVoucher: (bookingId: string) => void,
    onForceReloadBookingStatus: (bookingId: string, bookingLocator: string) => void,
    synchronizingId: string,
    totalCommision: number,
    totalPvp: number,
    totalElements: number,
    loading: boolean
}

const BookingsListComponent = (props: BookingsListComponentProps): JSX.Element => {
    const { t } = useTranslation();

    const appConfig = useAppConfig();
    useEffect(() => { console.log("total pvp", props.totalPvp) }, [props.totalPvp])

    const [selectedNumRecords, setSelectedNumRecords] = useState<number>(6);
    const [selectedPage, setSelectedPage] = useState<number>(1);

    const getBookingDetail = (bookingId: string): void => {
        props.onShowBookingDetail(bookingId);
    }


    const getCustomerVoucher = (bookingId: string): void => {
        props.onShowCustomerVoucher(bookingId);
    }

    const reloadBookingInfo = (bookingId: string, bookingLocator: string): void => {
        props.onForceReloadBookingStatus(bookingId, bookingLocator);
    }

    const getTagStatus = (record: BookingInfo): JSX.Element => {
        const colors: { [key: string]: string } = {
            'pending': 'blue',
            'pre': 'orange',
            'active': 'green',
            'canceled': 'red',
            'error': 'red'
        }

        //Cogemos el color de colors según el status del booking
        const tagColor = colors[record.statusName];

        return <div className="status">
            <Tag className="tag-status" color={tagColor}>
                {record.statusName.charAt(0).toUpperCase() + record.statusName.slice(1)
                }
                {
                    record?.isCancelled ?
                        <Tooltip title={t('pages.booking-detail.booking-status.cancelled-at', [record.cancelledDate ? moment(record.cancelledDate).format(appConfig.config.dateFormat) : 'N/D (1)'])}>
                            <InfoCircleOutlined className="icon-cancelled-at" />
                        </Tooltip>
                        :
                        <></>
                    //<SynchronizeButton synchronizing={props.synchronizingId === record.locator} bookingData={record} onSynchronize={reloadBookingInfo}/>
                }

            </Tag>
        </div>
    }

    const getprice = (record: BookingInfo): JSX.Element => {
        return (
            <div className="pricing-data">
                <div className="pvp">
                    <span>
                        {t('components.bookings-list.pvp') + ": "}
                        <MoneyComponent amountOriginal={record.pvp} currencyOriginal={record.currency} />
                    </span>
                </div>
                <div className="net">
                    <span>
                        {t('components.bookings-list.net') + ": "}
                        <MoneyComponent amountOriginal={record.net} currencyOriginal={record.currency} />
                    </span>
                </div>
                <div className="commission">
                    <span>
                        {t('components.bookings-list.commission') + ": "}
                        <MoneyComponent amountOriginal={record.commission} currencyOriginal={record.currency} />
                    </span>
                </div>
                <div className="payed">
                    <span>
                        {t('components.bookings-list.payed') + ": "}
                        <MoneyComponent amountOriginal={record.payed} currencyOriginal={record.currency} />
                    </span>
                </div>
            </div>
        );
    };


    const getLocatorInfo = (record: BookingInfo): JSX.Element => {
        return <div className="locator-data">
            <div className="locator">{record.locator}</div>
            <div className="creation-date">({moment(record.creationDate).format(appConfig.config.dateFormat)})</div>
        </div>
    }

    const getDatesInfo = (record: BookingInfo): JSX.Element => {
        return <div className="hotel-data">
            <div className="hotel-name">{record.hotelName}</div>
            <div className="check-in-out">
                ({moment(record.checkIn).format(appConfig.config.dateFormat)} - {moment(record.checkOut).format(appConfig.config.dateFormat)})
            </div>
        </div>
    }

    const getUserInfo = (record: BookingInfo): JSX.Element => {
        return <div className="user-data">
            <div className="user-name">{record.userName}</div>
            <div className="user-email">{record.userEmail}</div>
        </div>
    }

    const tableColumns: ColumnsType<BookingInfo> = [
        {
            title: t('components.bookings-list.status'),
            dataIndex: "status",
            key: "status",
            sorter: (a: BookingInfo, b: BookingInfo) => a.statusName.localeCompare(b.statusName),
            render: (item: string, record: BookingInfo) => {

                return <div className="locator-data">
                    {getTagStatus(record)}
                </div>
            }
        },
        {
            title: t('components.bookings-list.locator'),
            dataIndex: "locator",
            key: "locator",
            sorter: (a: BookingInfo, b: BookingInfo) => a.locator.localeCompare(b.locator),
            render: (item: string, record: BookingInfo) => {

                return getLocatorInfo(record)
            },

        },
        {
            title: t('components.bookings-list.booking'),
            dataIndex: "hotelName",
            key: "hotelName",
            sorter: (a: BookingInfo, b: BookingInfo) => a.hotelName.localeCompare(b.hotelName),
            render: (item: string, record: BookingInfo) => {

                return getDatesInfo(record)
            }
        },
        {
            title: t('components.bookings-list.owner'),
            dataIndex: "ownerName",
            key: "ownerName",
            sorter: (a: BookingInfo, b: BookingInfo) => a.ownerName.localeCompare(b.ownerName),
            render: (item: string, record: BookingInfo) => {
                return <div className="owner-data">
                    <div className="owner-name">{item}</div>
                </div>
            }
        },
        Table.EXPAND_COLUMN,
        {
            title: t('components.bookings-list.pricing-data'),
            dataIndex: "pvp",
            key: "pvp",
            render: (item: string, record: BookingInfo) => {

                return record.isPvp ? <MoneyComponent amountOriginal={record.pvp} currencyOriginal={record.currency} /> : <MoneyComponent amountOriginal={record.net} currencyOriginal={record.currency} />;
            }
        },
        {
            title: t('components.bookings-list.booking-by'),
            dataIndex: "userId",
            sorter: (a: BookingInfo, b: BookingInfo) => a.userName.localeCompare(b.userName),
            key: "userId",
            render: (item: string, record: BookingInfo) => {

                return getUserInfo(record)
            }
        },
        {
            title: t('components.bookings-list.actions'),
            dataIndex: "actions",
            key: "actions",
            render: (item: string, record: BookingInfo) => {
                return getActions(record);
            }
        }
    ];

    const getActions = (item: BookingInfo): JSX.Element => {
        return <div className="user-actions">
            <Tooltip title={t('components.bookings-list.actions-tooltip.booking-detail')}>
                <SnippetsOutlined className="app-icon icon" onClick={() => { getBookingDetail(item.bookingId) }} />
            </Tooltip>
            <Tooltip title={t('components.bookings-list.actions-tooltip.customer-voucher')}>
                <UserOutlined className="app-icon icon" onClick={() => { getCustomerVoucher(item.bookingId) }} />
            </Tooltip>
        </div>
    }

    useEffect(() => {
        props.search(selectedNumRecords, selectedPage);
    }, [selectedPage, selectedNumRecords])

    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const handleExpand = (expanded: boolean, record: BookingInfo) => {
        setExpandedRowKeys(expanded ? [record.bookingId] : []);
    };


    return (
        <div className="bookings-list">
            <div className="bookings-resume">
                <Row gutter={16}>
                    <Col xs={24} lg={4}>
                        {t('components.bookings-list.resume.total')}: <span>{props.totalElements}</span>
                    </Col>
                    <Col xs={24} lg={4}>
                        {t('components.bookings-list.resume.total-amount')}: <span><MoneyComponent amountOriginal={props.totalPvp} currencyOriginal={props.bookingsInfo.elements?.[0]?.currency} /> </span>
                    </Col>
                    <Col xs={24} lg={4}>
                        {t('components.bookings-list.resume.total-commissions')}: <span><MoneyComponent amountOriginal={props.totalCommision} currencyOriginal={props.bookingsInfo.elements?.[0]?.currency} /></span>
                    </Col>
                </Row>
            </div>
            <div className="list">
                {
                    appConfig.device === 'desktop' ?

                        <Table
                            // rowSelection={{}}
                            expandable={{
                                expandedRowKeys,
                                onExpand: handleExpand,
                                expandedRowRender: (record) => (
                                    <p style={{ margin: 0 }}>
                                        <div>
                                            {getprice(record)}
                                        </div>
                                    </p>
                                ),
                            }}
                            locale={{
                                triggerAsc: t('components.bookings-list.sort-asc')!,
                                triggerDesc: t('components.bookings-list.sort-desc')!,
                                cancelSort: t('components.bookings-list.no-sort')!,
                            }}
                            className="app-table"
                            columns={tableColumns}
                            dataSource={props.bookingsInfo?.elements}
                            loading={props.loading}
                            pagination={false}
                            rowKey={"bookingId"}
                        />
                        :
                        <List
                            className={`app-list`}
                            itemLayout="vertical"
                            dataSource={props.bookingsInfo?.elements}
                            loading={props.loading}
                            renderItem={(item: BookingInfo) =>
                                <BookingListItem item={item} getTagStatus={getTagStatus}
                                    getLocatorInfo={getLocatorInfo}
                                    getActions={getActions}
                                    getDatesInfo={getDatesInfo}
                                    getprice={getprice}
                                    getUserInfo={getUserInfo}
                                />
                            }
                        />
                }
            </div>
            {!props.loading && (
                <div className="total-records">
                    <div className="pagination">
                        <Pagination
                            locale={GetPaginationLocale(t)}
                            total={props.bookingsInfo?.totalRecords}
                            pageSizeOptions={["6", "20", "50", "100"]}
                            current={selectedPage}
                            pageSize={selectedNumRecords}
                            defaultPageSize={6}
                            defaultCurrent={1}
                            onChange={(page, numRecords) => {
                                setSelectedPage(page);
                                setSelectedNumRecords(numRecords);
                            }}
                        />
                    </div>
                    <div className="counter">
                        {t('components.company-selector.total-records', [props.bookingsInfo.totalRecords])}
                    </div>
                </div>

            )}

        </div>
    )
}

const SynchronizeButton = (props: { synchronizing: boolean, onSynchronize: (bookingId: string, bookingLocator: string) => void, bookingData: BookingInfo }): JSX.Element => {
    const { t } = useTranslation();


    const waitingTime = 60000;

    const [disabled, setDisabled] = useState<boolean>(false);

    const [counter, setCounter] = useState<number>(waitingTime);

    useEffect(() => {
        let countdown: NodeJS.Timeout | null = null;
        if (disabled) {
            countdown = setTimeout(() => {
                setDisabled(false);
                setCounter(waitingTime);
            }, waitingTime);
        }

        return () => {
            // Limpia el temporizador si el componente se desmonta o si disabled cambia a false
            if (countdown) {
                clearTimeout(countdown);
            }
        };
    }, [disabled]);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (disabled && counter > 0) {
            // Actualiza el contador cada segundo
            interval = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1000);
            }, 1000);
        }

        return () => {
            // Limpia el intervalo si el componente se desmonta o si disabled cambia a false
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [disabled, counter]);

    return (
        <Popconfirm
            okText={t('components.bookings-list.synchronize.ok-text')}
            cancelText={t('components.bookings-list.synchronize.cancel-text')}
            title={t('components.bookings-list.synchronize.title')}
            description={HTMLReactParser(t('components.bookings-list.synchronize.description', [props.bookingData.locator]))}
            onConfirm={() => {
                if (!disabled) {
                    props.onSynchronize(props.bookingData.bookingId, props.bookingData.locator)
                    setDisabled(true);
                } else {
                    notification.warning({
                        message: t('components.bookings-list.synchronize.time-exceeded'),
                        description: HTMLReactParser(t('components.bookings-list.synchronize.time-exceeded-description', [props.bookingData.locator, (counter / 1000)]))
                    })
                }
            }}
        >
            <Tooltip title={counter == waitingTime ? t('components.bookings-list.actions-tooltip.synchronize-booking') : HTMLReactParser(t('components.bookings-list.synchronize.time-exceeded-description', [props.bookingData.locator, (counter / 1000)]))}>
                <SyncOutlined disabled spin={props.synchronizing} className="app-icon icon" />
            </Tooltip>
        </Popconfirm>
    )
}

export default BookingsListComponent;