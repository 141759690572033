

import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'BookingEngine'

export const BookingEngineEP : EndPointConfig[] =[
    new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetUserSearchHistory"),
    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("PerformBooking"),
    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("GetLocationAvailability"),
    new EndPointConfig()
        .setMethod("POST")
        .setController(controller)
        .setActionName("GetHotelAvailability"),
    new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("SimulateCancelBooking"),
    new EndPointConfig()
        .setMethod("DELETE")
        .setController(controller)
        .setActionName("CancelBooking")

        
]