

import { useTranslation } from 'react-i18next';
import './BookingDataForm.scss'
import { Button, Checkbox, Col, Form, Input, Radio, Row, Space, Spin, Typography } from 'antd';
import { useCart } from '../../../Contexts/CartContexts';
import { CartRoomData } from '@beds2b-group/reusable-components/dist/types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import PerformBooking, { Pax, Policy } from '../../../models/booking/PerformBooking';
import { useSessionData } from '../../../Contexts/SessionDataContext';
import { performBooking } from '../../../api/repositoryEP';
import { GetFormattedUrl } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";
import { PaymentMethodData } from '../../../models/PaymentsMethodsData';


interface BookingDataFormProps {
    availData: any,
    paymentsMethods: PaymentMethodData[]
}

const BookingDataForm = forwardRef<any, BookingDataFormProps>((props, ref) => {


    const { t } = useTranslation();
    const navigate = useNavigate();

    const cartContext = useCart();
    const sessionDataContext = useSessionData();

    const payButtonRef = useRef<HTMLButtonElement | null>(null); // Referencia del botón de pago

    // Exponemos la función `scrollToButton` para que otro componente pueda usarla
    useImperativeHandle(ref, () => ({
        scrollToButton: () => {
            if (payButtonRef.current) {
                payButtonRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }));



    const [form] = Form.useForm();

    const [selectedPaymentType, setSelectedPaymentType] = useState<string>("");

    const [isConfirmBooking, setIsConfirmBooking] = useState<boolean>(false);
    const confirmBooking = () => {
        //Get cart data
        setIsConfirmBooking(true);
        var cartData = cartContext.data;


        console.log("CartData is", cartData)
        console.log("AvailData", props.availData)

        const paxes: Pax[] = [];
        const roomKeys = Object.keys(form.getFieldsValue()).filter((key) => key.includes('.name'));

        roomKeys.forEach((key) => {
            var roomKeyData = key.split('.');
            const roomIndex = parseInt(roomKeyData[1]);
            const paxType = roomKeyData[2];
            const paxIndex = parseInt(roomKeyData[3]);
            const field = roomKeyData[4];


            const name = form.getFieldValue(`room.${roomIndex}.${paxType}.${paxIndex}.name`);
            const firstSurname = form.getFieldValue(`room.${roomIndex}.${paxType}.${paxIndex}.firstSurname`);
            const secondSurname = form.getFieldValue(`room.${roomIndex}.${paxType}.${paxIndex}.secondSurname`);
            const age = form.getFieldValue(`room.${roomIndex}.${paxType}.${paxIndex}.age`);

            paxes.push({
                paxType: paxType == 'adult' ? 0 : 2,
                roomIndex: roomIndex,
                roomCode: cartData.rooms[roomIndex].code,
                name: name,
                firstSurname: firstSurname,
                secondSurname: secondSurname,
                age: age
            });
        });

        const isNetPrice = cartData.rooms.some((room) => !room.price.isPvp);

        const performBookingData: PerformBooking = {
            availabilityRequestId: cartData.cartHash,
            holder: {
                name: form.getFieldValue('ownerName'),
                firstSurname: form.getFieldValue('firstSurname'),
                secondSurname: form.getFieldValue('secondSurname') || "",
                email: form.getFieldValue('ownerEmail'),
                phone: {
                    prefix: '',
                    number: form.getFieldValue('ownerPhone')
                }
            },
            marketCode: sessionDataContext.userData.market,
            paymentMethod: selectedPaymentType,
            isNetPrice: isNetPrice,
            observationsForHotel: form.getFieldValue('observations'),
            paxes: paxes,
            hotelData: {
                address: cartData.hotelData.address,
                code: cartData.hotelData.code,
                name: cartData.hotelData.name,
                email: cartData.hotelData.email,
                mainImage: cartData.hotelData.mainImage ?? '',
                personOfContact: cartData.hotelData.personOfContact,
                phone: cartData.hotelData.phone,
                website: cartData.hotelData.website,
                longitude : 0,//cartData.hotelData.longitude,
                latitude : 0,//cartData.hotelData.latitude,
            },
            accommodationDates: {
                from: cartData.dates.checkIn.toISOString(),
                to: cartData.dates.checkOut.toISOString()
            },
            roomInfo: cartData.rooms.map((room) => {

                return {
                    roomIndex: room.roomIndex,
                    mainImage: room.mainImage,
                    code: room.code,
                    name: room.name,
                    priceInfo: {
                        currency: room.price.currency,
                        pvp: room.price.pvp,
                        net: room.price.net,
                        commission: room.price.commission,
                        isPvp: room.price.isPvp
                    },
                    occupancy: {
                        adults: room.occupancy.adults,
                        children: room.occupancy.childs,
                        childrenAges: room.occupancy.childsAges
                    },
                    mealInfo: room.mealInfo,
                    rateInfo: {
                        ...room.rateInfo,
                        cancelPolicies: {
                            ...room.rateInfo.cancelPolicies,
                            policies: (room.rateInfo.cancelPolicies.policies as Policy[]).filter(
                                (policy) => policy.code === room.mealInfo.code
                            ) // Filtra solo las policies con el code correcto
                        }
                    }



                }

            }),
            serviceInfo: [],

        };
        console.log("datos de entrada", performBookingData.roomInfo[0].rateInfo.cancelPolicies.policies)

        performBooking(performBookingData).then((response) => {
            if (response && response.code === 200 && response.data && response.data.locator) {
                cartContext.clearCart();
                sessionStorage.removeItem("lastSearchType");
                sessionStorage.removeItem("lastSearchCode");
                sessionStorage.removeItem("lastOccupancy");

                sessionStorage.removeItem("lastLabel");
                sessionStorage.removeItem("selectedCountryCode");
                sessionStorage.removeItem("selectedRegionCode");
                sessionStorage.removeItem("lastFrom");
                sessionStorage.removeItem("lastTo");

                //      sessionStorage.setItem("lastFrom", "Invalid date");
                //    sessionStorage.setItem("lastTo", "Invalid date");
                navigate(GetFormattedUrl(`private/payment-ok-customer-voucher/${response.data.locator}`));
            }
        }).finally(() => {
            setIsConfirmBooking(false);
        });
    }

    const onChangeSelectedPaymentType = (e: any) => {
        setSelectedPaymentType(e.target.value);
    }


    const onFinish = () => {
        confirmBooking();
    }


    useEffect(() => { console.log("cqambio ", selectedPaymentType) }, [selectedPaymentType]);



    return (
        <div className="booking-data-form">
            <Form form={form} layout="vertical" onFinish={onFinish} >
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.owner-data.title')}</Typography.Title>
                    <Row gutter={16}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="ownerDocument" label={t('components.booking-data-form.owner-data.document')}>
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                name="ownerName"
                                label={t('components.booking-data-form.owner-data.name')}
                                className="app-input">
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                                name="firstSurname" label={t('components.booking-data-form.owner-data.firstSurname')}>
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="secondSurname" label={t('components.booking-data-form.owner-data.secondSurname')}>
                                <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear onPressEnter={onFinish} />
                            </Form.Item>
                        </Col>
                  
                            <Col xs={24} lg={12}>
                                <Form.Item
                                    name="ownerEmail"
                                    label={t('components.booking-data-form.owner-data.email')}
                                    rules={[
                                        { required: true, message: t("forms.validations.required-field")! },
                                        {
                                            type: "email",
                                            message: t("forms.validations.invalid-format")!
                                        }
                                    ]}
                                >
                                    <Input
                                        type="text"
                                        onInput={(e: any) => e.target.value = e.target.value.toUpperCase()}
                                        className="app-input"
                                        allowClear
                                        onPressEnter={onFinish}
                                    />
                                </Form.Item>
                            </Col>

                      
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="ownerPhone"
                                label={t('components.booking-data-form.owner-data.phone')}
                                rules={[
                                    { required: true, message: t("forms.validations.required-field")! },
                                    {
                                        pattern: /^\+?[0-9\s-]{7,15}$/,
                                        message: t("forms.validations.invalid-format")!
                                    }
                                ]}
                            >
                                <Input
                                    type="text"
                                    className="app-input"
                                    allowClear
                                    onPressEnter={onFinish}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24}>
                            <Form.Item name="commercialMails">
                                <Checkbox className="accept-commercial-emails">{t('components.booking-data-form.owner-data.accept-commercial-emails')}</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.booking-data.title')}</Typography.Title>
                    {
                        cartContext.data.rooms.map((room, index) => {
                            return <div key={index}>
                                <RoomForm room={room} form={form} />
                            </div>
                        })
                    }
                </div>
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.additional-info.title')}</Typography.Title>
                    <Form.Item name="observations" label={t('components.booking-data-form.additional-info.observations')}>
                        <Input.TextArea className="app-input" allowClear rows={6} />
                    </Form.Item>
                </div>
                <div className="app-box-form">
                    <Typography.Title level={3} className='app-title'>{t('components.booking-data-form.payment-methods.title')}</Typography.Title>
                    <Col xs={24}>
                        <Radio.Group>
                            <Space direction="vertical">
                                {props.paymentsMethods.map((paymentMethod) => (
                                    <Radio
                                        key={paymentMethod.id}
                                        value={paymentMethod.id}
                                        defaultChecked={selectedPaymentType === paymentMethod.id}
                                        onChange={onChangeSelectedPaymentType}
                                        checked={selectedPaymentType === paymentMethod.id}
                                    >
                                        {paymentMethod.name}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>

                    </Col>
                    <Col xs={24} className="app-actions centered">
                        <Button
                            htmlType='submit'
                            ref={payButtonRef} // Asignamos la referencia al botón
                            disabled={isConfirmBooking || selectedPaymentType == ""}
                            className="app-button"
                            size='large'
                        //onClick={confirmBooking}
                        >
                            {
                                isConfirmBooking ? <Spin /> : <span className={`sol-icon-ok-circled`} style={{ color: 'white' }} />

                            }
                            {t('components.booking-data-form.actions.confirm-booking')}


                        </Button>
                    </Col>
                </div>
            </Form>
        </div>
    );
});

interface RoomFormProps {
    room: CartRoomData,
    form: any,
}
const RoomForm = (props: RoomFormProps) => {

    const { room, form } = props;

    const { t } = useTranslation();

    const numAdults = room.occupancy.adults;
    const numChilds = room.occupancy.childs;
    const childAges = room.occupancy.childsAges;

    const copyOfOwner = () => {
        form.setFieldsValue({
            [`room.${room.roomIndex - 1}.adult.${0}.name`]: form.getFieldValue("ownerName"),
            [`room.${room.roomIndex - 1}.adult.${0}.firstSurname`]: form.getFieldValue("firstSurname"),
            [`room.${room.roomIndex - 1}.adult.${0}.secondSurname`]: form.getFieldValue("secondSurname"),
        });
    }

    const getRowAdult = (index: number) => {
        return <Row gutter={16}>
            <Col xs={24} className={index === 0 ? 'copy-of-titular' : ''}>
                <Typography.Title level={4} className='guest-type-title'>{t('components.booking-data-form.booking-data.adult-number', [index + 1])}</Typography.Title>
                {
                    index === 0 ? <Button className="app-button" size='small' onClick={copyOfOwner}>{t('components.booking-data-form.booking-data.copy-of-owner')}</Button> : <></>
                }
            </Col>
            <Col xs={24} lg={8}>
                <Form.Item name={`room.${room.roomIndex - 1}.adult.${index}.name`} label={t('components.booking-data-form.booking-data.name')}>
                    <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear />
                </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
                <Form.Item name={`room.${room.roomIndex - 1}.adult.${index}.firstSurname`} label={t('components.booking-data-form.booking-data.firstSurname')}>
                    <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear />
                </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
                <Form.Item name={`room.${room.roomIndex - 1}.adult.${index}.secondSurname`} label={t('components.booking-data-form.booking-data.secondSurname')}>
                    <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear />
                </Form.Item>
            </Col>
        </Row>
    }

    const getRowChild = (index: number) => {
        const age = childAges[index];

        return <Row gutter={16}>
            <Col xs={24}>
                <Typography.Title level={4} className='guest-type-title'>{t('components.booking-data-form.booking-data.child-number', [index + 1])}</Typography.Title>
            </Col>
            <Col xs={24} lg={6}>
                <Form.Item name={`room.${room.roomIndex - 1}.childs.${index}.name`} label={t('components.booking-data-form.booking-data.name')}>
                    <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear />
                </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
                <Form.Item name={`room.${room.roomIndex - 1}.childs.${index}.firstSurname`} label={t('components.booking-data-form.booking-data.firstSurname')}>
                    <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear />
                </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
                <Form.Item name={`room.${room.roomIndex - 1}.childs.${index}.secondSurname`} label={t('components.booking-data-form.booking-data.secondSurname')}>
                    <Input type="text" onInput={(e: any) => e.target.value = e.target.value.toUpperCase()} className="app-input" allowClear />
                </Form.Item>
            </Col>
            <Col xs={24} lg={6}>
                <Form.Item
                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                    initialValue={age} name={`room.${room.roomIndex - 1}.childs.${index}.age`} label={t('components.booking-data-form.booking-data.age')}>
                    <Input disabled value={childAges[index]} type="number" className="app-input" allowClear />
                </Form.Item>
            </Col>
        </Row>
    }

    const [formData, setFormData] = useState<Array<JSX.Element>>([])

    useEffect(() => {
        console.log("All data -> ", numAdults, numChilds, childAges);

        let data = [];
        for (let i = 0; i < numAdults; i++) {
            data.push(getRowAdult(i));
        }

        for (let i = 0; i < numChilds; i++) {
            data.push(getRowChild(i));
        }
        setFormData(data);
    }, []);

    return (
        <>
            <Col xs={24}>
                <Typography.Title level={4} className='room-title'>{t('components.booking-data-form.booking-data.header-occupancy', [(room.roomIndex), room.name, numAdults, numChilds])}. {room.price.pvp} {room.price.currency}</Typography.Title>
            </Col>
            {formData}
        </>
    )
}

export default BookingDataForm;