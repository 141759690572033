import { HotelAvailabilityResponse } from "../models/availability/HotelAvailability";
import { LocationAvailabilityResponse } from "../models/availability/LocationsAvailability";

export type UserType = "agent" | "independent" | "business" | "events";
export enum UserTypeEnum  {"agent" , "independent" ,"business" , "events"}

export const EngineDestinationTypeHotel : number = 0;
export const EngineDestinationTypeLocation : number = 1;


export const emptyLocationAvailabilityResponse: LocationAvailabilityResponse = {hotels: [], locationAvailabilities: []};
export const emptyHotelAvailabilityResponse: HotelAvailabilityResponse = {hotels: [], hotelAvailabilities: [], crossSellingProducts: []};

export const PageSize : number = 10;

export const PaymentsMethodsdRefCodeNotPayedNeeded : string[] = ['pho'] // aqui tenemos los metodos de pago que no necesitan ser pagados directamente