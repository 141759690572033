import EndPointConfig from '../../models/api/EndPointConfig'

const controller = 'PaymentsConfiguration'

export const PaymentsConfigurationEP : EndPointConfig[] =[
        new EndPointConfig()
        .setMethod("GET")
        .setController(controller)
        .setActionName("GetPaymentsMethods"),

]