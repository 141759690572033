import {
  AlternativeDates,
  HotelProperties,
  RibbonError,
} from "@beds2b-group/reusable-components/dist/types";
import { AlternativeDay, Hotel, HotelStaticData } from "../models/availability/Commons";
import {
  AvailabilityType,
  LocationAvailability,
  LocationAvailabilityResponse,
  Restrictions,
} from "../models/availability/LocationsAvailability";
import { HotelCard } from "@beds2b-group/reusable-components";
import { EngineDestinationTypeHotel } from "./constants";
import { HotelLinkInfo } from "../models/AppConfig";


const Link = ({ handleClick, to, children, className }: any) => {
    return (
      <button className={className} onClick={handleClick}>
        <a href={to}>{children}</a>
      </button>
    )
  }

export function LocationToAppAvailability(  
  locationAvailability: LocationAvailabilityResponse,
  bookingEngineContext: any,
  modal: any,
  currencyConversionData: {currency: string, exchangeRate: number},
  hotels: HotelLinkInfo[]
): JSX.Element[] {
  let result: JSX.Element[] = [];

  console.log("Processing location availability", locationAvailability);
  
  locationAvailability.locationAvailabilities?.forEach(
    (availability: LocationAvailability, index: number) => {

      console.log("Processing availability", availability);

      let hotelData: Hotel | undefined = locationAvailability.hotels.find(
        (h) => {
          return h.code == availability.hotelCode;
        }
      );

      const staticHotelData = hotelData?.hotelStaticData?.pop();

      if (hotelData) {
        let hotelImages =
        staticHotelData && staticHotelData.images
            ? staticHotelData.images.map((image: any) => {
                return image.url;
              })
            : [];

        let alternativeDates: Array<AlternativeDates> = [];

        if (availability.alternativeDays) {
          availability.alternativeDays.forEach(
            (alternativeDay: AlternativeDay) => {
              let alternativeDate: AlternativeDates = {
                from: new Date(alternativeDay.AccommodationDates.From),
                to: new Date(alternativeDay.AccommodationDates.To),
                price: {
                  currency: alternativeDay.MinimalPrice.currencyIsoCode,
                  amount: alternativeDay.MinimalPrice.amount,
                },
              };

              alternativeDates.push(alternativeDate);
            }
          );
        }

        let services : any[] = [];

        let currencyCode = availability.minimumPrice.currencyIsoCode;
        let amount = availability.minimumPrice.amount;
        if (currencyConversionData.exchangeRate != 1) {
          currencyCode = currencyConversionData.currency;
          amount = amount * currencyConversionData.exchangeRate;
        }
        var hotelName = hotels.find((h) => h.code == availability.hotelCode)?.name;

        let hotel: HotelProperties = {
          code: hotelData?.code ?? "",
          images: hotelImages,
          starts: Number.parseInt(hotelData?.category ?? "0"),
          services: services,
          shortDescription: staticHotelData?.shortDescription,
          alternativeDays: alternativeDates,
          price: {
            currency: currencyCode,
            amount: Number.parseFloat(amount.toFixed(2)),
          },
          description: staticHotelData?.largeDescription ?? "LD ND",
          address: {
            city: hotelData?.address.cityName ?? "",
            country: hotelData?.address.countryName ?? "",
            geoLocation: {
              latitude: hotelData?.address.latitude ?? 0,
              longitude: hotelData?.address.longitude ?? 0,
            },
            line1: hotelData?.address.streetName ?? "",
            zipCode: "ZC N/D (11)",
          },
          name: hotelName ?? hotelData.name,
        };

        let hotelError: RibbonError | undefined = undefined;
        if (availability.availabilityType) {
          let type: string = "";

          switch (availability.availabilityType as AvailabilityType) {
            case AvailabilityType.HasAvailability:
              // Tiene dispo, así que no hay problema.
              break;
            case AvailabilityType.NoAvailabilityHotelClosed:
              hotelError = {
                code: "HotelClosed",
                translation:
                  "El hotel se encuentra cerrado en las fechas indicadas",
                classes: ["ribbon-error"],
                type: "error",
              };
              break;
            case AvailabilityType.NoAvailabilityNoquota:
              hotelError = {
                code: "HotelNoquota",
                translation:
                  "El hotel no dispone de cupo para las fechas indicadas",
                classes: ["ribbon-error"],
                type: "error",
              };
              break;
            case AvailabilityType.NoAvailabilityExistingRestrictions:
              let text: string = "";
              availability.restrictions.forEach((restriction) => {
                switch (restriction) {
                  case Restrictions.MinLos:
                    text = text + "El hotel tiene una estancia mínima de xxx noches";
                    break;
                  case Restrictions.MaxLos:
                    text = text + "El hotel tiene una estancia máxima de xxx noches";
                    break;
                  case Restrictions.Release:
                    text = text + "El hotel tiene una release de xxx noches";
                    break;
                  case Restrictions.Unknow:
                    text = text + "Existe una restricción no especificada en el sistema.";
                    break;
                }
              });

              hotelError = {
                code: "HotelRestrictions",
                translation: text,
                classes: ["ribbon-warning"],
                type: "warning",
              };

              break;
          
            default:
              break;
          }
        }

        result.push(
          <HotelCard
            key={index}
            Link={Link}
            handleBooking={async (data: any) => {
              let newContext = {
                searchType: EngineDestinationTypeHotel,
                searchCode: data.hotel.code,
                promotionalCode: bookingEngineContext.promotionalCode,
                label: data.hotel.name,
                dates: bookingEngineContext.dates,
                rooms: bookingEngineContext.rooms,
              };
              bookingEngineContext.updateContext(newContext);

              return new Promise((res) => null);
            }}
            routes={{
              alternativeDates: "",
              booking: "",
            }}
            hotel={hotel}
            showImagesModal={(props : any) => {
              modal.show("hotel-modal", hotel);
            }}
            error={hotelError}
          />
        );
      }
    }
  );

  return result;
}
