import { useTranslation } from 'react-i18next';
import './PrivacityPage.scss';
import HTMLReactParser from 'html-react-parser';

const PrivacityPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="privacity-page">
            <p className='bold'>{t('pages.privacity.title')}</p>
            <br></br>

            {/* Sección 1 */}
            <p>{t('pages.privacity.title-paragraph.1')}</p>
            <p>{t('pages.privacity.paragraph.1')}</p>
            <br></br>

            {/* Sección 2 */}
            <p>{t('pages.privacity.title-paragraph.2')}</p>
            <p>{t('pages.privacity.paragraph.2')}</p>
            <p>{t('pages.privacity.paragraph.3')}</p>
            <p>{t('pages.privacity.paragraph.4')}</p>
            <p>{HTMLReactParser(t('pages.privacity.paragraph.5'))}</p>
            <br></br>


            {/* Sección 3 */}
            <p>{t('pages.privacity.title-paragraph.3')}</p>
            <p>{t('pages.privacity.paragraph.6')}</p>
            <p>{t('pages.privacity.paragraph.7')}</p>
            <p>{t('pages.privacity.paragraph.8')}</p>
            <p>{t('pages.privacity.paragraph.9')}</p>
            <p>{t('pages.privacity.paragraph.10')}</p>
            <p>{t('pages.privacity.paragraph.11')}</p>
            <p>{t('pages.privacity.paragraph.12')}</p>
            <p>{t('pages.privacity.paragraph.13')}</p>
            <p>{t('pages.privacity.paragraph.14')}</p>
            <p>{t('pages.privacity.paragraph.15')}</p>
            <p>{t('pages.privacity.paragraph.16')}</p>
            
            <br></br>


            {/* Sección 4 */}
            <p>{t('pages.privacity.title-paragraph.4')}</p>
            <p>{t('pages.privacity.paragraph.17')}</p>
            <p>{t('pages.privacity.paragraph.18')}</p>
            <p>{t('pages.privacity.paragraph.19')}</p>
            <p>{t('pages.privacity.paragraph.20')}</p>
            
            <br></br>


            {/* Sección 5 */}
            <p>{t('pages.privacity.title-paragraph.5')}</p>
            <p>{t('pages.privacity.paragraph.21')}</p>
            <br></br>
            <p>{t('pages.privacity.paragraph.22')}</p>
            <p>{t('pages.privacity.paragraph.23')}</p>
            <p>{t('pages.privacity.paragraph.24')}</p>
            <p>{t('pages.privacity.paragraph.25')}</p>
            

            <br></br>


            {/* Sección 6 */}
            <p>{t('pages.privacity.title-paragraph.6')}</p>
            <p>{t('pages.privacity.paragraph.26')}</p>
            <p>{t('pages.privacity.paragraph.27')}</p>
            <p>{t('pages.privacity.paragraph.28')}</p>
            <p>{t('pages.privacity.paragraph.29')}</p>
            <p>{t('pages.privacity.paragraph.30')}</p>
            
            <br></br>


            {/* Sección 7 */}
            <p>{t('pages.privacity.title-paragraph.7')}</p>
            <p>{t('pages.privacity.paragraph.31')}</p>
            <p>{t('pages.privacity.paragraph.32')}</p>
            <p>{t('pages.privacity.paragraph.33')}</p>
            <p>{t('pages.privacity.paragraph.34')}</p>
            <p>{t('pages.privacity.paragraph.35')}</p>
            <p>{t('pages.privacity.paragraph.36')}</p>
            <p>{t('pages.privacity.paragraph.37')}</p>
            
            <br></br>
            <p>{HTMLReactParser(t('pages.privacity.paragraph.38'))}</p>
            <br></br>


            {/* Sección 8 */}
            <p>{t('pages.privacity.title-paragraph.8')}</p>
            <p>{t('pages.privacity.paragraph.39')}</p>

            <br></br>


            {/* Sección 9 */}
            <p>{t('pages.privacity.title-paragraph.9')}</p>
            <p>{t('pages.privacity.paragraph.40')}</p>
            <br></br>


            {/* Sección 10 */}
            <p>{t('pages.privacity.title-paragraph.10')}</p>
            <p>{HTMLReactParser(t('pages.privacity.paragraph.41'))}</p>
            <p>{HTMLReactParser(t('pages.privacity.paragraph.42'))}</p>
            <br></br>
        </div>
    );
}

export default PrivacityPage;
