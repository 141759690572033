import moment from 'moment';
import { useEffect, useState } from 'react';
import { CancellationPolicy, PaymentTypeEnum } from '../../../models/BookingData';
import { useTranslation } from 'react-i18next';
import MoneyComponent from '../../common/MoneyComponent/MoneyComponent';
import { Col, Row } from 'antd';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import HTMLReactParser from 'html-react-parser';
import { GetShowMoreIcon } from '../../../utils/icons';
import './CancellationPoliciesComponent.scss';

interface CancellationPoliciesComponentProps {
    cancellationPolicies: CancellationPolicy[];
    currencyCode: string;
}

const CancellationPoliciesComponent = ({ cancellationPolicies, currencyCode }: CancellationPoliciesComponentProps): JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();
    const [visible, setVisible] = useState<boolean>(false);

    const toggleVisibility = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        console.log("Las políticas de cancelación son:", cancellationPolicies);
    }, [visible]);

    const sortedPolicies = [...cancellationPolicies]
    .sort((a, b) => new Date(b.deadline).getTime() - new Date(a.deadline).getTime()); // Orden descendente

return (
    <div className="cancellation-policies-component">
        <div className="cancellation-policies" onClick={toggleVisibility}>
            {GetShowMoreIcon("plus-icon")} {t('pages.booking-detail.cancellation-policies')}
        </div>
        <ul className={`cancellation-policies-list ${visible ? 'visible' : 'hidden'}`}>
            {sortedPolicies.map((policy, index) => (
                <li key={index}>
                    <Row>
                        <Col xs={24} md={20}>
                            {t("pages.booking-detail.cancellation-policy.from")} <strong>{moment(policy.deadline).format(appConfig.config.dateFormat)}</strong>
                        </Col>
                        <Col xs={24} md={4}>
                            {policy.penalty === 0
                                ? t('pages.booking-detail.cancellation-policy.free')
                                : policy.penaltyType === PaymentTypeEnum.percent
                                    ? `${policy.penalty} %`
                                    : <MoneyComponent amountOriginal={policy.penalty} currencyOriginal={currencyCode} />}
                        </Col>
                    </Row>
                </li>
            ))}
        </ul>
    </div>
);
}

export default CancellationPoliciesComponent;
