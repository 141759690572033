import { useTranslation } from 'react-i18next';
import './LegalWarningPage.scss';
import HTMLReactParser from 'html-react-parser';

const LegalWarningPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="legal-warning">
            <p className='bold'>{t('pages.legal-warning.title')}</p>
            <br></br>

            {/* Sección 1 */}
            <p>{t('pages.legal-warning.title-paragraph.1')}</p>
            <p>{t('pages.legal-warning.paragraph.1')}</p>
            <p>{HTMLReactParser(t('pages.legal-warning.paragraph.2'))}</p>
            <br></br>

            {/* Sección 2 */}
            <p>{t('pages.legal-warning.title-paragraph.2')}</p>
            <p>{t('pages.legal-warning.paragraph.3')}</p>
            <br></br>


            {/* Sección 3 */}
            <p>{t('pages.legal-warning.title-paragraph.3')}</p>
            <p>{t('pages.legal-warning.paragraph.4')}</p>
            <p>{t('pages.legal-warning.paragraph.5')}</p>
            <p>{t('pages.legal-warning.paragraph.6')}</p>
            <br></br>



            {/* Sección 4. */}
            <p>{t('pages.legal-warning.title-paragraph.4')}</p>
            <p>{t('pages.legal-warning.paragraph.7')}</p>
            <p>{t('pages.legal-warning.paragraph.8')}</p>
            <br></br>



            {/* Sección 5 */}
            <p>{t('pages.legal-warning.title-paragraph.5')}</p>
            <p>{t('pages.legal-warning.paragraph.9')}</p>
            <p>{t('pages.legal-warning.paragraph.10')}</p>
            <p>{t('pages.legal-warning.paragraph.11')}</p>
            <br></br>


            {/* Sección 6 */}
            <p>{t('pages.legal-warning.title-paragraph.6')}</p>
            <p>{t('pages.legal-warning.paragraph.12')}</p>
            <p>{t('pages.legal-warning.paragraph.13')}</p>
            <br></br>


            {/* Sección 7 */}
            <p>{t('pages.legal-warning.title-paragraph.7')}</p>
            <p>{t('pages.legal-warning.paragraph.14')}</p>
            <p>{t('pages.legal-warning.paragraph.15')}</p>
            <br></br>

            {/* Sección 8 */}
            <p>{t('pages.legal-warning.title-paragraph.8')}</p>
            <p>{t('pages.legal-warning.paragraph.16')}</p>
            <br></br>

            {/* Sección 9 */}
            <p>{t('pages.legal-warning.title-paragraph.9')}</p>
            <p>{t('pages.legal-warning.paragraph.17')}</p>
            <p>{HTMLReactParser(t('pages.legal-warning.paragraph.18'))}</p>
            <br></br>

        </div>
    );
}

export default LegalWarningPage;